import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // make sure to install react-router-dom if not already installed
import { useUser } from "../../context/UserContext";

const PurchaseConfirmation = () => {
  const navigate = useNavigate();
  const { fetchTokenBalance } = useUser();

  const goToMainPage = () => {
    navigate("/");
  };

  useEffect(() => {
    // See if checkout was successful and update token balance
    const urlParams = new URLSearchParams(window.location.search);
    const session_id = urlParams.get("session_id");
    if (session_id) {
      fetchTokenBalance();
    }
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Thank You For Your Purchase!</h1>
      <p style={styles.text}>
        Your transaction has been completed. <br />A receipt for your purchase
        has been emailed to you.
      </p>
      <button style={styles.button} onClick={goToMainPage}>
        Back to Storyteller
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // This will take the full height of the viewport
    textAlign: "center",
    backgroundColor: "#f7f7f7",
    padding: "20px",
  },
  header: {
    color: "#333",
    marginBottom: "20px",
  },
  text: {
    color: "#555",
    marginBottom: "30px",
    maxWidth: "600px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
  },
};

export default PurchaseConfirmation;
