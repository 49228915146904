import React, { useEffect, useState } from "react";
import { auth } from "../../firebase/firebase-config"; // Adjust the path as necessary
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({ toggleSignUpModal }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    // Clean up the listener
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <header className="header">
      <div className="logo">Storyteller App</div>
      <nav className="navigation">
        {/* <a href="/about">About</a>
                <a href="/contact">Contact</a> */}
        {isLoggedIn ? (
          <button onClick={handleSignOut}>Sign Out</button>
        ) : (
          <>
            <span
              onClick={() => toggleSignUpModal("signin")}
              style={{ cursor: "pointer" }}
            >
              Sign In
            </span>
            <span
              onClick={() => toggleSignUpModal("signup")}
              style={{ cursor: "pointer", marginLeft: "20px" }}
            >
              Sign Up
            </span>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
