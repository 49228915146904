import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillLightningFill } from "react-icons/bs";
import "./StoryGenerator.css";
import { fetchStory, generatePrompt } from "../../services/storyService";
import { useUser } from "../../context/UserContext";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import PurchaseModal from "../PurchaseModal/PurchaseModal";
import Modal from "../Modal/Modal";

const StoryGenerator = ({ toggleSignUpModal }) => {
  const [prompt, setPrompt] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [audioUrl, setAudioUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [showTranscript, setShowTranscript] = useState(false);
  const [rows, setRows] = useState(5);
  const [isProcessing, setIsProcessing] = useState(false);
  // const placeholder = "I accidentally walk in on my best friend's sister touching herself, and she begs me not to tell anyone. She says she'll do anything..."
  const placeholder = "Enter your prompt here...";

  const {
    user,
    tokenBalance,
    setTokenBalance,
    isSubscriber,
    updateUser,
    isFeedbackSubmitted,
  } = useUser();

  const wordsPerMinute = 125;
  const tokensPerMinute = 1;
  const storyCost = minutes * tokensPerMinute;
  const canGenerate = !user || tokenBalance >= storyCost;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handlePromptChange = (e) => setPrompt(e.target.value);
  const handleMinutesChange = (e) => setMinutes(e.target.value);

  useEffect(() => {
    const lines = prompt.split("\n").length;
    const newRows = lines >= 6 ? lines : 6; // Minimum of 5 rows
    setRows(newRows);
  }, [prompt]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!user) {
      // Show sign-up modal for non-signed-in user
      setTimeout(() => {
        toggleSignUpModal();
        setIsLoading(false);
      }, 2000);
      return;
    }

    const requestData = {
      wordCount: minutes * wordsPerMinute,
      fantasies: prompt,
    };

    try {
      const response = await fetchStory(requestData);
      setAudioUrl(response.audioUrl);
      setTranscript(response.transcript);
      setShowTranscript(false);
      const newTokenBalance = tokenBalance - storyCost; // Calculate the new token balance
      await updateUser(newTokenBalance); // Update user in Firestore and local state
    } catch (error) {
      console.error("Error fetching data: ", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGeneratePrompt = async () => {
    setIsGeneratingPrompt(true);
    try {
      const newPrompt = await generatePrompt({
        wordCount: 50,
        genre: "straight",
      });
      setPrompt(newPrompt);
    } catch (error) {
      console.error("Error generating prompt: ", error.message);
    } finally {
      setIsGeneratingPrompt(false);
    }
  };

  const toggleTranscript = () => {
    setShowTranscript(!showTranscript);
  };

  return (
    <div className="storyGenerator">
      <form onSubmit={handleSubmit}>
        <textarea
          value={prompt}
          onChange={handlePromptChange}
          placeholder={placeholder}
          rows={rows}
        />
        <button
          onClick={handleGeneratePrompt}
          disabled={isGeneratingPrompt}
          className="generatePromptBtn"
        >
          {isGeneratingPrompt ? (
            <CircularProgress size={16} color={"grey"} />
          ) : (
            <BsFillLightningFill />
          )}
          &nbsp;Get random scenario
        </button>
        <div className="slider-container">
          <p style={{ marginBottom: "0px" }}>
            Story length: {minutes} {minutes == 1 ? "minute" : "minutes"}
          </p>
          <input
            type="range"
            value={minutes}
            onChange={handleMinutesChange}
            min="1"
            max="5"
            step="1"
          />
        </div>
        <button
          type="submit"
          className="generateBtn"
          disabled={!canGenerate || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Make my story!"}
        </button>
      </form>
      <button
        onClick={openModal}
        className="buyTokensBtn"
        style={canGenerate ? { visibility: "hidden" } : {}}
      >
        Get More Minutes
      </button>
      <Modal isOpen={isModalOpen} close={closeModal}>
        <PurchaseModal />
      </Modal>
      {audioUrl && <AudioPlayer audioUrl={audioUrl} transcript={transcript} />}
    </div>
  );
};

export default StoryGenerator;
