import React, { useState } from 'react';
import { auth } from '../../firebase/firebase-config';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignInPage.css';

const SignInPage = ({close}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignIn = async (event) => {
        event.preventDefault();
        setError('');
        try {
            await signInWithEmailAndPassword(auth, email, password);
            close();
        } catch (error) {
            setError(error.message);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setError('Please enter your email address.');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            alert('Password reset link sent! Check your email.');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="signInContainer"> {/* Update class name to match the CSS */}
            <h2 className="signInTitle">Sign In</h2>
            {error && <p className="errorMessage">{error}</p>}
            <form onSubmit={handleSignIn}>
                <div className="inputGroup">
                    <label>Email:</label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                </div>
                <div className="inputGroup">
                    <label>Password:</label>
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                </div>
                <button type="submit" className="signInButton">Sign In</button>
            </form>
            <button onClick={handleForgotPassword} className="forgotPassword">
                Forgot Password?
            </button>
        </div>
    );
};

export default SignInPage;
