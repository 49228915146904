import React, { createContext, useState, useContext } from 'react';

const FeedbackDataContext = createContext();

export const useFeedbackData = () => useContext(FeedbackDataContext);

export const FeedbackDataProvider = ({ children }) => {
    const [feedbackData, setFeedbackData] = useState({
        listeningFrequency: '',
        favoriteTopics: '',
        mostLiked: '',
        leastLiked: '',
        appImprovements: '',
        featureRanking: {}
    });

  return (
      <FeedbackDataContext.Provider value={{ feedbackData, setFeedbackData }}>
          {children}
      </FeedbackDataContext.Provider>
  );
};
