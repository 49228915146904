import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, close, children, className = "small" }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      close();
    }
  };

  return (
    <div className="modalOverlay" onClick={handleOverlayClick}>
      <div className={`modalContent ${className}`}>
        <button onClick={close} className="closeButton">
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
