import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import StoryGenerator from '../../components/StoryGenerator/StoryGenerator';
import TokenDisplay from '../../components/TokenDisplay/TokenDisplay';
import { FeedbackDataProvider } from '../../context/FeedbackContext';
import './HomePage.css';
import { useUser } from '../../context/UserContext';
import Modal from '../../components/Modal/Modal';
import SignInOrSignUpModal from '../../components/SignInOrSignUpModal/SignInOrSignUpModal';


const HomePage = () => {
  const { user, tokenBalance, setTokenBalance, isSubscriber, updateUser } = useUser();
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [modalMode, setModalMode] = useState('signup');

  
  const toggleSignUpModal = (mode = 'signup') => {
    console.log('mode: ', mode);
      setModalMode(mode);
      setShowSignUpModal(!showSignUpModal);
  };

  


  return (
      <FeedbackDataProvider>
          <div className="homePage">
              <Header toggleSignUpModal={toggleSignUpModal}/>
              <main className="mainContent">
                {user && <TokenDisplay />}
                  <section className="storyCreationSection">
                      <h1>Your Personal F4M Storyteller</h1>
                      <p>Generate intimate audio from any scenario</p>
                      <StoryGenerator toggleSignUpModal={toggleSignUpModal} />
                  </section>
              </main>
            <Modal isOpen={showSignUpModal} close={() => setShowSignUpModal(false)} className="large">
              <SignInOrSignUpModal mode={modalMode} close={() => setShowSignUpModal(false)} />
            </Modal>
          </div>
      </FeedbackDataProvider>
  );
};

export default HomePage;