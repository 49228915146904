import React, { useState } from "react";
import { useUser } from "../../context/UserContext";
import { useFeedbackData } from "../../context/FeedbackContext";
import { CircularProgress } from "@mui/material";
import "./FeedbackForm.css";

const FeedbackForm = ({ onCloseFeedback, showFeedbackForm }) => {
  const { feedbackData, setFeedbackData } = useFeedbackData();
  const {
    user,
    updateUser,
    submitFeedback,
    tokenBalance,
    setTokenBalance,
    setIsFeedbackSubmitted,
  } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tokensForFeedback = 10;
  const featureIdeas = [
    "Choose a voice to read my story",
    "Non-speech sounds from the speaker (e.g. sighing, moaning)",
    "Sound effects (e.g. mouth sounds)",
    "Tags to choose from (e.g. [Blowjob], [First Time], [Friends To Lovers]",
    "Ability to SAVE the stories you create",
    "Ability to EDIT + re-record the stories you create",
    "Ability to hear stories OTHERS have generated",
  ];

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (user) {
      setTimeout(async () => {
        await submitFeedback(feedbackData);
        setIsSubmitting(false);
        setTokenBalance(tokenBalance + tokensForFeedback);
        onCloseFeedback();
      }, 1200);
    }
  };

  // Check if the form is complete
  const isFormComplete = () => {
    const {
      listeningFrequency,
      favoriteTopics,
      mostLiked,
      leastLiked,
      appImprovements,
      featureRanking,
    } = feedbackData;
    const basicFieldsFilled =
      listeningFrequency &&
      favoriteTopics &&
      mostLiked &&
      leastLiked &&
      appImprovements;
    const allFeaturesRanked = featureIdeas.every(
      (feature) =>
        featureRanking[feature] !== undefined && featureRanking[feature] !== ""
    );
    return basicFieldsFilled && allFeaturesRanked;
  };

  // Handle input change
  const handleInputChange = (key, value) =>
    setFeedbackData({ ...feedbackData, [key]: value });

  // Handle feature ranking change
  const handleFeatureRankingChange = (feature, rank) => {
    const updatedRanking = { ...feedbackData.featureRanking, [feature]: rank };
    setFeedbackData({ ...feedbackData, featureRanking: updatedRanking });
  };

  if (isSubmitting) {
    return (
      <div className="submittingFeedback">
        <p>Thank you! Your feedback is submitting.</p>
        <CircularProgress size={24} />
        <p>
          <strong>You've earned {tokensForFeedback} minutes 💰</strong>
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="feedbackForm">
      <p className="topText">
        Your feedback here will go DIRECTLY into improving this app. <br />
        As a thank you, you’ll get 10 free minutes! 🙏
      </p>

      <div className="feedbackFormContent">
        <div className="feedbackSection">
          <div className="feedbackSectionTitle">Your preferences</div>

          <label>
            How often do you listen to audio erotica?
            <select
              value={feedbackData.listeningFrequency}
              onChange={(e) =>
                handleInputChange("listeningFrequency", e.target.value)
              }
            >
              <option value="">Select frequency</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="rarely">Rarely</option>
            </select>
          </label>
          <label>
            On the days you DO listen to erotica, how many minutes do you
            typically spend?
            <select
              value={feedbackData.sessionLength}
              onChange={(e) =>
                handleInputChange("sessionLength", e.target.value)
              }
            >
              <option value="">Select time range</option>
              <option value="daily">0-10 minutes</option>
              <option value="weekly">10-20 minutes</option>
              <option value="monthly">20-30 minutes</option>
              <option value="rarely">30-45 minutes</option>
              <option value="rarely">45-60 minutes</option>
              <option value="rarely">Over 1 hour</option>
              <option value="rarely">Overy 2 hours</option>
            </select>
          </label>
          <label>
            How long is your ideal erotic recording?
            <select
              value={feedbackData.favoriteLength}
              onChange={(e) =>
                handleInputChange("favoriteLength", e.target.value)
              }
            >
              <option value="">Select time range</option>
              <option value="daily">Just a few minutes</option>
              <option value="weekly">5-10 minutes</option>
              <option value="monthly">15-20 minutes</option>
              <option value="rarely">Half an hour</option>
              <option value="rarely">45 minutes or more</option>
            </select>
          </label>
          <label>
            Any favorite subjects or categories? (please feel free to include a
            link to any favorite recordings you have!)
            <input
              type="text"
              value={feedbackData.favoriteTopics}
              onChange={(e) =>
                handleInputChange("favoriteTopics", e.target.value)
              }
            />
          </label>
          {/* <label>
            What do you think are the MOST IMPORTANT things about a great nsfw
            recording? (e.g. great writing, sexy voice, sound effects)
            <input
              type="text"
              value={feedbackData.mostImportantQualities}
              onChange={(e) =>
                handleInputChange("mostImportantQualities", e.target.value)
              }
            />
          </label> */}
        </div>
        <div className="feedbackSection">
          <div className="feedbackSectionTitle">Your experience so far </div>

          <label>
            So far, what do you like about this app?
            <textarea
              value={feedbackData.mostLiked}
              onChange={(e) => handleInputChange("mostLiked", e.target.value)}
            />
          </label>
          <label>
            What do you like LEAST about this app?
            <textarea
              value={feedbackData.leastLiked}
              onChange={(e) => handleInputChange("leastLiked", e.target.value)}
            />
          </label>
          <label>
            What specific improvements or new features would you like to see?
            <textarea
              value={feedbackData.appImprovements}
              onChange={(e) =>
                handleInputChange("appImprovements", e.target.value)
              }
            />
          </label>
          <div className="feedbackSection">
            <div className="feedbackSectionTitle">Important features</div>
            <p
              style={{
                color: "black",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <strong>
                Please rank how important each of the below features are to you.
              </strong>
              <br />
              (1 = Not important 5 = Extremely important)
            </p>
            {featureIdeas.map((feature) => (
              <div key={feature} className="featureRanking">
                <p>{feature}</p>
                <div className="rankingOptions">
                  {[1, 2, 3, 4, 5].map((rank) => (
                    <div key={rank} className="rankingOption">
                      <input
                        type="radio"
                        name={feature}
                        value={rank}
                        checked={feedbackData.featureRanking[feature] === rank}
                        onChange={() =>
                          handleFeatureRankingChange(feature, rank)
                        }
                      />
                      <label>{rank}</label>
                      <div className="important-label">
                        {rank === 1 ? "Not Important" : ""}
                        {rank === 5 ? "Most Important" : ""}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="feedbackSection">
          <div className="feedbackSectionTitle">Pricing</div>
          <label>
            Is this app today something you would pay to use?
            <input
              type="text"
              value={feedbackData.wouldPayNow}
              onChange={(e) => handleInputChange("wouldPayNow", e.target.value)}
            />
          </label>
          <label>
            If we incorporated your suggestions above, do you expect you would
            pay to use it?
            <input
              type="text"
              value={feedbackData.wouldPayFuture}
              onChange={(e) =>
                handleInputChange("wouldPayFuture", e.target.value)
              }
            />
          </label>
          <label>
            Generative AI tools are quite expensive today, so it currently costs
            us 30-40 cents per minute to create your stories.{" "}
            <strong>
              That means creating a 5-minute audio story may cost around $2.
            </strong>
            <br />
            <br />
            Do you PERSONALLY see yourself using this service, given that price?
            If not, what's the highest price you'd pay?
            <input
              type="text"
              value={feedbackData.wouldPay2Dollars}
              onChange={(e) =>
                handleInputChange("wouldPay2Dollars", e.target.value)
              }
            />
          </label>
          <label>
            Any other comments or suggestions?
            <textarea
              value={feedbackData.otherComments}
              onChange={(e) =>
                handleInputChange("otherComments", e.target.value)
              }
            />
          </label>
        </div>

        <button type="submit" disabled={!isFormComplete()}>
          Submit feedback for 10 free minutes! ⭐️
        </button>
        <div style={{ opacity: "0" }}> e</div>
      </div>
    </form>
  );
};

export default FeedbackForm;
