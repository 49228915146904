import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { useUser } from "../../context/UserContext";
import "./AudioPlayer.css";

const AudioPlayer = ({ audioUrl, transcript }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const { isSubscriber } = useUser();
  const [showTranscript, setShowTranscript] = useState(false);

  const toggleTranscript = () => {
    setShowTranscript(!showTranscript);
  };

  // Function placeholders for future implementation
  const handleEditTranscript = () => {
    // TODO: Implement transcript editing
  };

  const handleSaveToLibrary = () => {
    // TODO: Implement save to library
  };

  console.log("audioUrl:", audioUrl);

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#D9DCFF",
      progressColor: "#4353FF",
      cursorColor: "#4353FF",
      barWidth: 2,
      barRadius: 3,
      responsive: true,
      height: 50,
      normalize: true,
      backend: "MediaElement",
    });

    if (audioUrl) {
      console.log("audioUrl:", audioUrl);
      wavesurfer.current.load(audioUrl);
    } else {
      console.log("No audioUrl provided");
    }

    return () => wavesurfer.current.destroy();
  }, [audioUrl]);

  const handlePlayPause = () => {
    console.log("audioUrl:", audioUrl);
    wavesurfer.current.playPause();
  };

  return (
    <div className="audioPlayer">
      <div id="waveform" ref={waveformRef}></div>
      <button onClick={handlePlayPause}>Play/Pause</button>
      {isSubscriber && (
        <>
          <button onClick={toggleTranscript}>
            {showTranscript ? "Hide" : "Show"} Transcript
          </button>
          <button onClick={handleSaveToLibrary}>Save to Library</button>
        </>
      )}
      {showTranscript && (
        <div className="transcript">
          <p>{transcript}</p>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
