import React, { useState } from "react";
import { createCheckoutSession } from "../../services/purchaseService";
import "./PurchaseModal.css";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import { useUser } from "../../context/UserContext";

const PurchaseModal = () => {
  const { tokenBalance, isFeedbackSubmitted } = useUser();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const handlePurchase = async (option) => {
    const stripeUrl = await createCheckoutSession(option);
    // window.open(stripeUrl, "_blank");
    window.location.href = stripeUrl;
  };

  const handleFeedbackOption = () => {
    setShowFeedbackForm(true);
  };

  const handleCloseFeedback = () => {
    setShowFeedbackForm(false);
  };

  return (
    <div className="purchase-modal">
      {!showFeedbackForm && (
        <div className="purchase-container">
          <div className="credits-info">
            You have
            <p className="credits-amount">{tokenBalance} Minutes</p>
          </div>
          <div className="purchase-options">
            <button
              className="purchase-button"
              onClick={() => handlePurchase("3_tokens")}
            >
              Buy 3 minutes for $2
            </button>
            <button
              className="purchase-button"
              onClick={() => handlePurchase("10_tokens")}
            >
              Buy 10 minutes for $5
            </button>
            <button
              className="purchase-button"
              onClick={() => handlePurchase("25_tokens")}
            >
              Buy 25 minutes for $10
            </button>
          </div>
        </div>
      )}

      {!isFeedbackSubmitted && (
        <>
          {showFeedbackForm ? (
            <div className="feedback-modal">
              <FeedbackForm
                onCloseFeedback={handleCloseFeedback}
                showFeedbackForm={showFeedbackForm}
              />
            </div>
          ) : (
            <button
              onClick={handleFeedbackOption}
              className="feedback-option-button"
            >
              Get 10 Minutes Free
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default PurchaseModal;
