import React, { useState } from "react";
import { useUser } from "../../context/UserContext";
import Modal from "../Modal/Modal";
import PurchaseModal from "../PurchaseModal/PurchaseModal";
import "./TokenDisplay.css";

const TokenDisplay = () => {
  const { tokenBalance, isFeedbackSubmitted } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleGetMoreTokens = () => {
    // Implement navigation or modal opening for purchasing more tokens
    console.log("Navigate to token purchase page or open purchase modal");
  };

  return (
    <>
      <div className="tokenDisplay">
        Minutes remaining: {tokenBalance}{" "}
        <button onClick={openModal} className="getMoreTokensBtn">
          Get More
        </button>
      </div>
      <Modal isOpen={isModalOpen} close={closeModal}>
        <PurchaseModal />
      </Modal>
    </>
  );
};

export default TokenDisplay;
