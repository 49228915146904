import React, { useState } from 'react';
import { auth } from '../../firebase/firebase-config.js';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignUpPage.css'; // Import the CSS file for styling

const SignUpPage = ({close}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignUp = async (event) => {
        event.preventDefault();
        setError('');
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            close();
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="signupPage">
            <div className="signupContainer">
                <h2 className="signupTitle">Your story is waiting...</h2>
                <p className="signupIntro">Continue for free and let your imagination run wild</p>
                <div className="benefitsList">
                    {/* <p>Instantly unlock:</p> */}
                    <ul>
                        <li> 🎧 20 free minutes of custom intimate audio</li>
                        <li>🤖 Invent any scenario with the help of AI</li>
                        <li>🤫 Access NSFW content with no limits</li>
                        <li> 🎙️ Realistic voices for an immersive experience</li>
                        <li>🔒 Strict privacy and data protection</li>
                    </ul>
                </div>
                {error && <p className="errorMessage">{error}</p>}
                <form onSubmit={handleSignUp} className="signupForm">
                    <div className="inputGroup">
                        <label>Email:</label>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="inputGroup">
                        <label>Password:</label>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <button type="submit" className="signupButton">Tell me my story!</button>
                </form>
            </div>
        </div>
    );
};

export default SignUpPage;
