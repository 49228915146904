import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage.js";
import { UserProvider } from "./context/UserContext";
import SignInPage from "./pages/SignInPage/SignInPage.js";
import SignUpPage from "./pages/SignUpPage/SignUpPage.js";
import PurchaseConfirmation from "./pages/PurchaseConfirmation/PurchaseConfirmation.js";

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/confirm" element={<PurchaseConfirmation />} />
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
