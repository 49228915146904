import { auth } from "../firebase/firebase-config";

const createCheckoutSession = async (option) => {
  try {
    const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);

    const response = await fetch(
      "https://gaipi.replit.app/create-checkout-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // Send the chosen option to the server to determine the price and create a checkout session
        body: JSON.stringify({ option, idToken }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.url; // This should be the url returned from your server
  } catch (error) {
    console.error("Error creating checkout session: ", error.message);
    throw error; // Re-throw to handle it in the component
  }
};

export { createCheckoutSession };
