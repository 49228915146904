import React, { useState } from "react";
import SignInPage from "../../pages/SignInPage/SignInPage";
import SignUpPage from "../../pages/SignUpPage/SignUpPage";
import "./SignInOrSignUpModal.css";

const SignInOrSignUpModal = ({ mode = "signup", close }) => {
  const [isSignIn, setIsSignIn] = useState(mode === "signin"); // Toggle between sign-in and sign-up

  const toggleForm = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <div>
      {isSignIn ? <SignInPage close={close} /> : <SignUpPage close={close} />}
      <p style={{ textAlign: "center" }}>
        {isSignIn ? "Don't have an account? " : "Already have an account? "}
        <button onClick={toggleForm}>{isSignIn ? "Sign up" : "Sign in"}</button>
      </p>
    </div>
  );
};

export default SignInOrSignUpModal;
