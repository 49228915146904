import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase/firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

const firestore = getFirestore();
const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [isSubscriber, setSubscriber] = useState(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false); // New state
  const newUserTokens = 10;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser) {
        console.log("EXISTING USER");
        const userRef = doc(firestore, "users", firebaseUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setTokenBalance(userData.tokenBalance);
          console.log("token balance:", userData.tokenBalance);
          setSubscriber(userData.isSubscriber);
          setIsFeedbackSubmitted(userData.isFeedbackSubmitted || false);
        } else {
          // This block should only run for genuinely new users.
          const initialUserData = {
            tokenBalance: newUserTokens,
            isSubscriber: false, // Assuming new users aren't subscribers by default.
            isFeedbackSubmitted: false,
          };
          await setDoc(userRef, initialUserData);
          setTokenBalance(newUserTokens);
          setSubscriber(false);
          setIsFeedbackSubmitted(false);
        }
      } else {
        // Handle sign-out state or no user logged in.
        setTokenBalance(0);
        setSubscriber(false);
        setIsFeedbackSubmitted(false);
      }
    });

    return unsubscribe;
  }, []);

  const updateUser = async (
    newTokenBalance = tokenBalance,
    newIsSubscriber = isSubscriber
  ) => {
    if (user) {
      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, {
        tokenBalance: newTokenBalance,
        isSubscriber: newIsSubscriber,
      });
      setTokenBalance(newTokenBalance);
      setSubscriber(newIsSubscriber);
    }
  };

  const submitFeedback = async (feedbackData) => {
    if (!user) return; // Check if the user is defined

    try {
      // Add feedback data to a 'feedback' collection with a document ID that matches the user's UID
      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, {
        feedback: feedbackData,
        feedbackSubmittedAt: serverTimestamp(),
        isFeedbackSubmitted: true,
        tokenBalance: tokenBalance + 10,
      });
      setIsFeedbackSubmitted(true);

      console.log("Feedback submitted successfully.");
    } catch (error) {
      console.error("Error submitting feedback: ", error);
    }
  };

  const fetchTokenBalance = async () => {
    if (user) {
      const userRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setTokenBalance(userData.tokenBalance); // Update token balance in context
      } else {
        console.error("User document does not exist");
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        tokenBalance,
        setTokenBalance,
        isSubscriber,
        setSubscriber,
        updateUser,
        isFeedbackSubmitted,
        submitFeedback,
        fetchTokenBalance,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
