import { getAuth, getIdToken } from "firebase/auth";

const fetchStory = async (requestData) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User not authenticated");
  }

  const idToken = await getIdToken(user);

  try {
    const response = await fetch("https://gaipi.replit.app/story", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Check if the server response contains the audio data in a Buffer object
    if (data.audioUrl && data.audioUrl.type === "Buffer") {
      // Convert the buffer to a Blob
      const blob = new Blob([new Uint8Array(data.audioUrl.data)], {
        type: "audio/mpeg",
      });
      // Create an object URL from the Blob
      data.audioUrl = URL.createObjectURL(blob);
    }

    return {
      audioUrl: data.audioUrl, // This is now a URL that can be used in an audio element
      transcript: data.transcript,
    };
  } catch (error) {
    console.error("Error fetching data: ", error.message);
    throw error;
  }
};

const generatePrompt = async (requestData) => {
  try {
    console.log("generating prompt...");
    const response = await fetch("https://gaipi.replit.app/generate-prompt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    console.log("response:", response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.prompt;
  } catch (error) {
    console.error("Error generating prompt: ", error.message);
    throw error; // Re-throw to handle it in the component
  }
};

export { fetchStory, generatePrompt };
